import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialType } from 'app/social-media-share/social-media-share.component';
import { SocialMediaShareComponent } from '../../social-media-share/social-media-share.component';
import { SocialMediaChooseComponent } from '../../social-media-choose/social-media-choose.component';

@Component({
    selector: 'app-home-about-slide',
    templateUrl: './home-about-slide.component.html',
    styleUrls: ['./home-about-slide.component.css'],
    standalone: true,
    imports: [SocialMediaChooseComponent, SocialMediaShareComponent]
})
export class HomeAboutSlideComponent {
  public socialType: SocialType | null = null;

  constructor(public modalService: NgbModal) {}

  openModal(shareModal: TemplateRef<any>, class_: string) {
    let ref = this.modalService.open(shareModal, {
      modalDialogClass: class_,
    });
    ref.closed.subscribe(() => {this.socialType = null;})
    ref.dismissed.subscribe(() => {this.socialType = null;})
  }
}
