<div class="start mt-5 help-page" id="lets-meet-page" *ngrxLet="data$ as data">
  <div class="front-info show-after" [hidden]="!showTip">
    <button class="btn-close float-end m-3" aria-label="Закрыть" (click)="showTip=false;"></button>
    <div class="text-container fs-5">
      <p>
        Кликните на любую карточку, чтобы появился вопрос для обсуждения. Тот, кто выбрал карточку, отвечает на
      вопрос первым.</p>
      <p>Не забывайте менять очередность.</p>
      <p>Нет никакого лимита для обсуждения вопроса, как только вы будете готовы,
      кликните на следующую карточку — вас ждет новый вопрос.</p>
      <div [innerHtml]="data.additional_instructions"></div>
      <button class="btn fs-4 w-100" (click)="showTip = false;">Всё понятно</button>
      <img class="my-3" width="30%" src="/assets/lets_black_logo.svg">
    </div>
  </div>
  <div class="cards-is-shown show-after collapse fs-5"
    [class.show]="allCardsAreShown">
    <div class="inner-text fs-5">
      <p>
        Спасибо. Надеемся, что этот первый разговор прошел успешно.
        Вы можете обсудить другие темы<span *ngIf="randomSection">, например,
        <a [routerLink]="randomSection.url">{{randomSection.label}}</a></span>.
        Или <a routerLink="/partners">обратиться к специалисту для обсуждения
        вопросов с профессиональным психологом</a>
      </p>
      <a class="btn fs-4 w-100" data-bs-toggle="modal" data-bs-target="#aboutUs"
      role="button">Понятно</a>
    </div>
  </div>
  <div [hidden]="!continueSh1" class="save-link show-after">
    <button class="btn-close float-end m-3" aria-label="Закрыть" (click)="continueSh1=false;"></button>
    <p>Вы можете сохранить ссылку и открыть ее снова до окончания сеанса игры:</p>
    <a [href]="getUrl()" class="text-break fw-bold" style="text-transform: none;">{{getUrl()}}</a>
    <a class="btn fs-3 w-100" (click)="continueSh1=false"
    role="button">Понятно</a>
  </div>
  <div [hidden]="!continueSh2" class="save-link show-after">
    <button class="btn-close float-end m-3" aria-label="Закрыть" (click)="continueSh2=false;"></button>
    <button type="button" class="btn-close" (click)="continueSh2=false"></button>
    <br>Вы можете доплатить еще 500 рублей и получить доступ к игре на месяц!
    <a class="btn fs-3 w-100" (click)="continueSh2=false; buy()"
    role="button">Доплатить</a>
  </div>
  <ng-template [ngIf]="linkExpired">
      <app-timer [linkExpired]="linkExpired"></app-timer>
  </ng-template>

  <div class="container-fluid">
    <h1 class="h2 fw-lighter text-center fw-bolder mb-4 header-card" [innerText]="data.title"></h1>
    <div *ngIf="data.children" class="d-flex justify-content-end mb-3 me-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="showForChildren" [checked]="showForChildren | ngrxPush"
        (change)="showForChildren.next(!showForChildren.value)">
        <label for="showForChildren" class="form-check-label">Показать карточки для тех, у кого есть дети</label>
      </div>
    </div>
    <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 gx-5 gy-4 fw-bold justify-content-start">
      <ng-container *ngFor="let category of data.categoryList">
        <div *ngFor="let card of category.cards" class="col">
          <div class="card my-card"
          [class.was-chosen]="openedCards.has(card.image)"
          (click)="open(card)"
          data-bs-toggle="modal" data-bs-target="#cardModal">
            <img [src]="card.image" class="card-img-top face" alt="...">
            <img [src]="category.back_image" class="card-img-top back" alt="...">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="text-center addition ps-lg-5 ps-sm-0 pt-3">
    <p class="ps-lg-5 pe-lg-5 mt-5 fw-bold">Eсли вы понимаете, что в вашей коммуникации образовались провалы, которые самостоятельно преодолеть слишком больно или невозможно, обратитесь к семейному психотерапевту.</p>
    <p class="ps-lg-5 pe-lg-5 mt-5">
      Выберите подходящего специалиста
    </p>
    <a class="btn btn-lg nav-link pink-button mb-3 fs-4" routerLink="/partners"
      role="button" *ngIf="data.slug != 'talk'; else alter_partner">Наши<br>партнеры</a>
    <ng-template #alter_partner>
      <a class="btn btn-lg nav-link info-btn mb-3"
        href="https://alter.ru/client-survey?promo_code=letsplay&utm_source=partners&utm_medium=igradavay&utm_content=letsplay"
        >Подобрать<br>психолога в Alter</a>
    </ng-template>
  </div>
</div>

<!-- Selected card -->
<div class="modal fade" id="cardModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-body">
      <img *ngIf="selectedCard" class="open-card" [src]="selectedCard.image">
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade modal-about-us" id="aboutUs" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-about-us-modal></app-about-us-modal>
      </div>
    </div>
  </div>
</div>
