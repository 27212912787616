<div class="mt-lg-3 px-1 pt-5 text-center">
  <h3 class="light fw-bolder text-wrap fs-3">
    Как можно рассказать об игре?
  </h3>

  <div class="text-block m-auto">
    <p class="mt-5 fs-5"> Вы можете поделиться рассказом об игре «Давай», просто нажав на иконку подходящей вам соцсети.</p>
    <p class="mt-4 d-none d-md-block fs-5"> Мы заранее подготовили для Telegram — красивый текст,
      объясняющий, в чем суть игры; для Вконтакте — примеры карточек с вопросами; а для Twitter — остроумную подпись к кликабельной ссылке.</p>
    <div class="d-flex flex-row mt-3 justify-content-center">
      <div class="mx-md-3 mx-1 w-fit-content" *ngFor="let socialType of socialTypes">
        <label>
          <button [class]="socialType + ' social'"
            (click)="chosen.emit(socialType)"></button>
          <span class="visually-hidden">{{socialType}}</span>
        </label>
      </div>
    </div>


    <p class="mt-3 fs-5">Если вашим друзьям точно нужна эта игра — вы можете просто
      прислать им ссылку на наш сайт. А еще мы готовим настольную версию игры. Подписывайтесь на наш Telegram, чтобы не пропустить анонс:)</p>

    <div class="d-flex flex-row mt-1 justify-content-center">
      <div class="w-fit-content">
        <a href="https://t.me/igradavay" class="mt-2">
          <div class="telegram-publish social"></div>
        </a>
      </div>
    </div>
  </div>
</div>
