<div class="slide" style="padding-top: 5%">
  <div class="divorce-page-bg "></div>
  <div class="sex-page ps-5" id="sex-page">
    <p class="display-2 right-pad fw-bold ps-5 fs-2">
      Давай
    </p>
    <div class="light ok-enum pe-3 mb-5">
      <p class="ps-5 pe-5 fs-4"> К сожалению, произошла ошибка.</p>
    </div>
    <button class="btn btn-lg nav-link ms-5 rounded-5 fs-4" style='background-color: #ff9fd3' routerLink=""
            role="button">На главную</button>
  </div>
</div>
