<div class="footer">
  <app-social-media-choose (chosen)="socialType = $event; openModal(shareModal, 'modal-lg modal-about-us');"></app-social-media-choose>
</div>

<!-- Modal -->
<ng-template #shareModal let-modal>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="modal.dismiss()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <app-social-media-share [socialType]="socialType"></app-social-media-share>
    </div>
</ng-template>

