<div class="fourth-page container-fluid">
  <div class="first-part">
    <img class="fly-img" src="/assets/fly.svg">
    <div class="row pt-4 align-items-center mb-5">
      <div class="col-sm-5 mb-3 mb-sm-4">
        <img width="450px" src="/assets/lets_black_logo.svg"
             class="float-lg-end float-md-end float-sm-start lets-image">
      </div>
      <div class="col-sm-5">
        <p class="fs-3 ms-lg-5 ms-md-5 ms-sm-0 mt-sm-5 me-3 smaller-text leave-comment">
          Уже попробовали нашу игру? <br/> Расскажите нам, как она <br/> повлияла на вас и ваши <br/>отношения.
        </p>
      </div>
    </div>

    <div class="row mt-3 d-none d-md-flex">
      <div class="col-5" style="margin-top: 150px;">
        <div style="padding-left: 3vw; margin-bottom: 2vw;">
          <div class="arrow-bg">
            <p class="text-all-comment fs-4 smaller-text">
              Все отзывы можно почитать тут
            </p>
          </div>
        </div>
        <p class="fw-bold fs-4 show-sm">
          Все отзывы можно почитать тут:
        </p>
        <div class="btn pink-button text-center fw-bold w-75 fs-4 button-read" routerLink="/comments">
          <a>Узнать, как игра “Давай”<br/> помогла другим парам</a>
        </div>
      </div>
      <div class="col-5">
        <div class="w-75">
          <app-cms-form path="/story"></app-cms-form>
        </div>
      </div>
    </div>

    <div class="row d-block d-md-none">
      <div class="col-12">
        <a class="btn btn-primary d-block m-auto fs-3 blue-button" routerLink="/story">Оставьте отзыв</a>
      </div>
      <div class="col-12">
        <div class="btn pink-button text-center w-75 mx-auto fs-3 button-read" routerLink="comments">
          Читать все отзывы
        </div>
      </div>
    </div>
  </div>
</div>
