import { enableProdMode, LOCALE_ID, importProvidersFrom, Injectable } from '@angular/core';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import localeRu from '@angular/common/locales/ru';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideHttpClient, HttpEvent, HttpRequest, withInterceptors, HttpHandlerFn } from '@angular/common/http';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { resolveLink } from './app/network/check-link';
import { GameComponent } from './app/games/game.component';
import { GamePageComponent } from './app/game-pages/game-page.component';
import { FailPaymentCheckComponent } from './app/fail-payment-check/fail-payment-check.component';
import { ExtendSuccessPaymentCheckComponent } from './app/extend-success-payment-check/extend-success-payment-check.component';
import { PaymentStatusResolver } from './app/network/check-payment-status';
import { SuccessPaymentCheckComponent } from './app/success-payment-check/success-payment-check.component';
import { LinkDoesNotExistComponent } from './app/link-does-not-exist/link-does-not-exist.component';
import { FailedComponent } from './app/failed/failed.component';
import { ExtendBuyComponent } from './app/extend-buy/extend-buy.component';
import { LinkFailureComponent } from './app/link-failure/link-failure.component';
import { HomeComponent } from './app/home/home.component';
import { CmsFormComponent } from 'app/forms/cms-form/cms-form.component';
import { ContentPageComponent } from 'app/cms/content-page/content-page.component';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CMSService, homePageData, hasDeckWithSlug, deckPageData, deckCards } from './app/network/cms.service';
import { HttpService } from './app/network/http.service';
import { GetPageDataResolverService, WagtailComponent, WagtailModule, WagtailModuleConfig } from 'angular-wagtail';
import { Observable } from 'rxjs';

const checkPaymentStatus = new PaymentStatusResolver(
  'cards/check-order/',
  'success-payment',
  'fail-payment',
).resolve;
const checkExtendPaymentStatus = new PaymentStatusResolver(
  'cards/check-extend-order/',
  'extend-success-payment',
  'extend-fail-payment',
).resolve;

const deckAndPagesRoutes: Routes = [
  {
    path: ':slug',
    canMatch: [hasDeckWithSlug],
    resolve: { page: deckPageData },
    children: [
      { path: '', component: GamePageComponent },
      {
        path: ':url', component: GameComponent,
        resolve: { linkData: resolveLink, cards: deckCards }
      },
    ]
  },
  {
    path: '**',
    component: WagtailComponent,
    resolve: {cmsData: GetPageDataResolverService},
    runGuardsAndResolvers: 'always',
  },
]

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  { path: 'try-free', redirectTo: 'free/try' },

  { path: 'link-expired/:url', component: LinkFailureComponent },
  { path: 'extend-buy/:url', component: ExtendBuyComponent },
  { path: 'failed', component: FailedComponent },
  { path: 'link-does-not-exist/:url', component: LinkDoesNotExistComponent },
  {
    path: 'success-payment/:orderId',
    component: SuccessPaymentCheckComponent,
    resolve: { status: checkPaymentStatus }
  },
  {
    path: 'extend-success-payment/:orderId',
    component: ExtendSuccessPaymentCheckComponent,
    resolve: { status: checkExtendPaymentStatus }
  },
  {
    path: 'fail-payment/:orderId',
    component: FailPaymentCheckComponent,
    resolve: { status: checkPaymentStatus }
  },
  {
    path: 'extend-fail-payment/:orderId',
    component: FailPaymentCheckComponent,
    resolve: { status: checkExtendPaymentStatus }
  },
  {
    path: 'preview',
    children: deckAndPagesRoutes,
    data: {preview: true},
  },
  {
    path: '',
    children: deckAndPagesRoutes,
  },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
};

const wagtailConfig: WagtailModuleConfig = {
  apiDomain: environment.backendURL,
  pagesApiPath: '/api/pages/detail_by_path/',
  pagesDraftApiPath: '/api/page_preview/detail_by_path/',
  notFoundComponent: FailedComponent,
  pageTypes: [
    {
      type: "feedback.EmailForm",
      component: CmsFormComponent,
    },
    {
      type: "igradavay.ContentPage",
      component: ContentPageComponent,
    }
  ]
}

if (environment.production) {
  enableProdMode();
}

function interceptWithCredentials(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  req = req.clone({withCredentials: true});
  return next(req);
}

registerLocaleData(localeRu);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      NgbModule,
      RouterModule.forRoot(appRoutes, routerOptions),
      WagtailModule.forRoot(wagtailConfig),
      MatButtonModule,
      MatCardModule,
      MatIconModule,
      MatToolbarModule,
      MatMenuModule,
      MatDividerModule,
    ),
    HttpService,
    CMSService,
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: APP_BASE_HREF, useValue: '/' },
    provideAnimations(),
    provideHttpClient(withInterceptors([interceptWithCredentials]))
  ]
})
  .catch(err => console.error(err));
