<header *ngrxLet="menu_items$ as menu_items">
  <mat-toolbar class="d-flex flex-row">
    <div>
      <button mat-icon-button [disabled]="!upButtonIsVisible"
       [matMenuTriggerFor]="dropMenu" class="app-toolbar">
        <svg viewBox="0 0 35 23">
          <use xlink:href="/assets/menu.svg#menu"></use>
        </svg>
      </button>
      <mat-menu #dropMenu="matMenu" class="mt-lg-1 mt-md-2 mt-sm-5"
                [class]="!upButtonIsVisible ? 'd-md-none' : ''">
        <ng-container *ngFor="let item of menu_items">
          <ng-container *ngIf="item.children && item.children.length">
            <button mat-menu-item
                    (click)="$event.stopPropagation(); dropdownMenu.hidden = !dropdownMenu.hidden">
              <div>
                <!--hack to make icon appear at the end-->
                <span class="mdc-list-item__primary-text">{{item.label}}</span>
                <mat-icon *ngIf="dropdownMenu.hidden">expand_more</mat-icon>
                <mat-icon *ngIf="!dropdownMenu.hidden">expand_less</mat-icon>
              </div>
            </button>
            <div #dropdownMenu hidden>
              <button *ngFor="let itemDropdown of item.children" mat-menu-item
                      [routerLink]="itemDropdown.url">
                <span *ngIf="itemDropdown.is_new" class="p-1 ps-3 pe-3 me-2 chip">NEW!</span>
                {{itemDropdown.label}}
              </button>
            </div>
          </ng-container>
          <button *ngIf="item.url" mat-menu-item
                  [routerLink]="item.url">
            {{item.label}}
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <mat-divider style="margin-left: 5vw"></mat-divider>
    <div class="flex-wrapper d-flex flex-row justify-content-center align-self-end flex-grow-1">
      <div class="item-bar d-none d-md-block">
        <ng-container *ngFor="let item of menu_items">
          <div ngbDropdown class="d-inline-block" *ngIf="item['children'] && item.children.length">
            <button mat-button class="items" ngbDropdownToggle>
              {{ item.label }}
            </button>
            <div ngbDropdownMenu>
              <button *ngFor="let itemInner of item.children"
                      ngbDropdownItem mat-button
                      [routerLink]="itemInner.url"
                      class="items-dropdown text-uppercase d-block">
                <span *ngIf="itemInner.is_new" class="chip">NEW!</span>
                {{itemInner.label}}
              </button>
            </div>
          </div>
          <button *ngIf="item.url" mat-button
                  [class]="'items ' + (item.classname || '')"
                  [routerLink]="item.url">
            {{ item.label }}
          </button>
        </ng-container>
      </div>
    </div>
  </mat-toolbar>
</header>


