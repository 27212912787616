import { ChangeDetectionStrategy, Component, ElementRef, HostListener, inject, Input, NgZone, OnInit, OnDestroy, signal, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageData } from 'app/cms/cms-page/cms-page.component';
import { WagtailService } from 'angular-wagtail';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/network/http.service';
import { CommonModule } from '@angular/common';
import { environment } from 'environments/environment';
import { Observable, map, filter } from 'rxjs';

interface CmsFormPage {
  form: string;  // HTML form contents
  preamble: string;
  side_pane: string;
  footer: string;
}

interface FormPageContent {
  title: string;
  preamble?: SafeHtml;
  side_pane?: SafeHtml;
  footer: string;
}

@Component({
  selector: 'app-cms-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cms-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsFormComponent implements OnInit, OnDestroy {
  @Input("path") pathOverride: string | null = null;
  @ViewChild("userform", {static: true}) userFormElement?: ElementRef<HTMLFormElement>;
  url!: string;
  content?: FormPageContent;
  formContents = signal<SafeHtml>('');
  error = signal<boolean>(false);
  storageKey?: string;

  private http = inject(HttpService);
  private sanitizer = inject(DomSanitizer);
  private wagtail = inject(WagtailService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  html(s: string, defined?: false): SafeHtml | undefined;
  html(s: string, defined: true): SafeHtml;

  html(s: string, defined?: boolean) {
    return (s || defined) ? this.sanitizer.bypassSecurityTrustHtml(s) : undefined;
  }


  ngOnInit() {
    if (this.pathOverride) {
      this.wagtail.getPageForUrl(this.pathOverride).subscribe((pageData) => {
        if (pageData) {
          this.setStorageKey(pageData.id);
          this.formContents.set(this.html((pageData as PageData<CmsFormPage>).form, true));
          // Don't set content for inline mode
        }
      })
      this.url = new URL(this.pathOverride, environment.backendURL).href;
    } else {
      const cmspage = this.route.snapshot.data['cmsData'] as PageData<CmsFormPage>;
      this.content = {
        title: cmspage.title,
        preamble: this.html(cmspage.preamble),
        side_pane: this.html(cmspage.side_pane),
        footer: cmspage.footer,
      }
      this.setStorageKey(cmspage.id);
      this.formContents.set(this.html(cmspage.form, true));
      this.url = new URL(this.router.url, environment.backendURL).href;
    }
    if (!this.url.endsWith('/')) {
      this.url = this.url + '/';
    }
  }

  onSubmit(form: HTMLFormElement, event: SubmitEvent) {
    event.preventDefault();
    this.error.set(false);
    this.clearSavedForm();
    this.http.requestText('post', form.action, new FormData(form)).subscribe({
      next: (result) => {
        this.formContents.set(this.html(result, true));
        setTimeout(() => this.scrollToError(), 0);
        setTimeout(() => this.saveFormData(), 0);
      },
      error: (err) => { this.error.set(true); },
    })
  }

  scrollToError(): void {
    let form = this.userFormElement?.nativeElement;
    if (!form) return;
    let first_error = form.querySelector(".errorlist");
    let relevant_input: HTMLInputElement | null = form.querySelector(".errorlist ~ input");
    first_error?.scrollIntoView();
    relevant_input?.focus();
    relevant_input?.select();
  }

  setStorageKey(id: number): void {
    this.storageKey = `cmsform-${id}-data`;
    setTimeout(() => this.restoreFormData(), 0);
  }

  // Saving and restoring form contents on nav
  // Look what they need to mimic a fraction of browser's power!

  @HostListener('window:pagehide')
  saveFormData(): void {
    let form = this.userFormElement?.nativeElement;
    if (!form || !this.storageKey) return;
    let data: Record<string, any> = {};
    for (let i = 0; i < form.elements.length; ++i) {
      const elt = form.elements.item(i)! as any;
      if (elt instanceof HTMLInputElement && elt.type == 'hidden') {
        continue;
      }
      if (elt.name && elt.value) {
        data[elt.name] = elt.value;
      }
    }
    try {
      sessionStorage.setItem(this.storageKey, JSON.stringify(data));
    } catch (e) {
      console.error(e);  // eh w/e
    }
  }

  restoreFormData(): void {
    let form = this.userFormElement?.nativeElement;
    if (!form || !this.storageKey) return;
    let data: Record<string, any>;
    const stored = sessionStorage.getItem(this.storageKey);
    if (!stored) return;
    try {
      const some_data = JSON.parse(stored);
      if (some_data.constructor !== Object) {
        throw TypeError("form data is not an object")
      }
      data = some_data;
    } catch (e) {
      console.error(e);
      return;
    }
    for (const [k, v] of Object.entries(data)) {
      const elt = form.elements.namedItem(k);
      if (elt) {
        (elt as any).value = v;
      }
    }
  }

  clearSavedForm(): void {
    if (this.storageKey) sessionStorage.removeItem(this.storageKey);
  }

  @HostListener('document:visibilitychange')
  onVisibilityChange() {
    if (document.visibilityState == 'hidden') {
      this.saveFormData();
    } else if (document.visibilityState == 'visible') {
      this.restoreFormData();
    }
  }

  ngOnDestroy(): void {
    this.saveFormData();
  }
}
