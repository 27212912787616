<div class="my-5 px-5 container-fluid" *ngIf="content?.preamble as preamble" [innerHtml]="preamble"></div>
<h1 class="mb-5 fs-3" *ngIf="content?.title as title" [innerText]="title"></h1>
<div class="insert-dark row" [class.fullpage-block]="!pathOverride">
  <div class="col-md">
    <form ngNoForm class="user-form" method="post" [action]="url" novalidate #userform
      (submit)="onSubmit(userform, $event)" [innerHtml]="formContents()">
    </form>
    <div class="errorlist mt-2" *ngIf="error()">
      <!-- bootstrap-icons/x.svg -->
      <svg xmlns="http://www.w3.org/2000/svg" class="danger" width="1em" height="1em" viewBox="0 0 16 16">
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
      </svg>
      <span>Что-то пошло не так. Пожалуйста, напишите нам письмо на
        <a href="mailto:igradavay.help@gmail.com">igradavay.help&#64;gmail.com</a></span>
    </div>
  </div>
  <div class="col-md" *ngIf="content?.side_pane as side_pane" [innerHtml]="side_pane"></div>
  <div class="w-100 text-center" *ngIf="content?.footer as footer" [innerText]="footer"></div>
</div>
