<div class="third-page">
  <div class="first-part container-fluid">
    <img class="a-b" src="/assets/a-b.svg">
    <div class="row pt-4 mb-3">
      <div class="col-sm-5 mb-3 mb-sm-4">
        <img width="250px" src="/assets/lets_white_logo.svg" class="float-lg-end float-md-end float-sm-start lets-image">
      </div>
      <div class="col-sm-6 mt-0">
        <img class="a-b-phone" src="/assets/a-b.svg">
        <p class="fs-3 ms-lg-5 ms-md-5 ms-sm-0 mt-lg-3 mt-md-3 mt-sm-5 me-3 smaller-text">
          Можно рассматривать <br/>как самостоятельный <br/>процесс психотерапии
        </p>
        <img class="b-c-phone" src="/assets/b-c.svg">
      </div>
    </div>
    <div class="row pt-4 align-items-center mb-5">
      <p class="light text-left fs-5 pt-5 smaller-text-2 text-process">
        Если в процессе ответов на вопросы из наших интерактивных колод вы поймете, что в вашей с партнером коммуникации образовались провалы, <br/>которые самостоятельно преодолеть слишком больно или невозможно, обратитесь к семейному
        психотерапевту
      </p>
    </div>
    
    <p class="light text-center fs-4 pt-5 tighter">
      Выберите подходящего специалиста
    </p>
    <div class="centering"><a class="btn btn-lg btn-info pink-button fs-4" routerLink="partners">Наши партнеры</a></div>
    <img class="b-c" src="/assets/b-c.svg">
  </div>

  <app-marquee class="bg-white fw-bold mt-5 text-center fs-4">| К себе и к другим с заботой и нежностью</app-marquee>
</div>
