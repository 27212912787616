<div class="background"></div>
<div class="ps-lg-5 ps-md-5 first-page text-uppercase" id="first-page" style="padding-bottom: 100px; padding-top: 10px">
    <div class="mt-5" style="margin:0 auto; text-align:center;"> <img src="/assets/нет.png" alt="..." width="70px"></div>
    <div class="text-start text-white fw-bold text m-auto" style="width: 70%;">
        <p class="text-center fs-3 fw-bold mt-3 mb-5" style="color: #F89575">
            Oops! Оплата не прошла
        </p>
        <p class="fs-3 fw-bold mb-5">Номер заказа: {{ orderId }}</p>
        <p class="fw-bold fs-4 mb-5" *ngIf="message">Причина ошибки: платёж {{ message }}</p>
        <div class="fw-light fs-5">
            <p>Проверьте, все ли в порядке:</p>
            <ul>
                <li>вы пользуетесь картой российского банка,</li>
                <li>вы вводите данные своей карты без ошибок и опечаток (такое случается со всеми),</li>
                <li>вы уверены, что на карте есть 499 рублей для оплаты заказа,</li>
                <li>вы не используете VPN.</li>
            </ul>
            <p class="mt-5 fs-5">Если все в порядке, но оплата так и не проходит, свяжитесь с нами через <a style="color: #F89575" href='/feedback#contact-form' >форму обратной связи</a> или по почте: igradavay.help&#64;gmail.com</p>
        </div>
        <p>Обязательно разберемся, в чем дело! </p>
    </div>
</div>
