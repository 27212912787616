<main role="main" id="start">
  <app-home-first-slide></app-home-first-slide>

  <app-home-second-slide></app-home-second-slide>

  <app-home-third-slide></app-home-third-slide>

  <app-home-fourth-slide></app-home-fourth-slide>

  <app-home-about-slide></app-home-about-slide>

  <p class="text-center text-black pt-5 background-bottom footer">ИГРА ДАВАЙ (с) 2023 ИП БЕРЕЖНАЯ В. Р. IGRADAVAY.HELP&#64;GMAIL.COM</p>
</main>
